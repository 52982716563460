import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button, Container } from '@mui/material'
import { Field, Form, Formik, FormikProps } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

import { ErrorDisplay, Image, SeoHeaders } from 'shared/components'
import { setFormError } from 'shared/services'

import NavBar from '../../components/nav_bar'
import GuitaLogo from '../../images/guita-logo.svg'
import { ADMIN_LOGIN_MUTATION } from '../../queries/auth'
import { isLoggedIn, setCredential } from '../../services/auth'
import { translateGuitaError } from '../../services/error_messages'
import { setManagedDomain } from '../../services/managed_domain'

import type { AdminLoginData, AdminLoginVars } from '../../queries/auth'

type FormValues = AdminLoginVars

const initialValues: FormValues = {
  domain: '',
  email: '',
  password: '',
  otp: '',
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  domain: Yup.string()
    .required('Este campo es obligatorio'),
  email: Yup.string()
    .email('Tu email es inválido')
    .max(64, 'Tu email es muy largo')
    .required('Este campo es obligatorio'),
  password: Yup.string()
    .min(6, 'Tu contraseña es muy corta')
    .max(128, 'Tu contraseña es muy larga')
    .required('Este campo es obligatorio'),
  otp: Yup.string()
    .length(6, 'El código debe tener 6 dígitos')
    .matches(/^\d+$/, 'El código debe contener sólo números'),
})

const LoginForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
}: FormikProps<FormValues>) => (
  <Form
    onSubmit={(event) => {
      event?.preventDefault()
      submitForm()
    }}
    style={{ width: '100%' }}
  >
    <Field
      required
      name='domain'
      type='text'
      label='Dominio'
      component={TextField}
      margin='normal'
      inputProps={{
        autoComplete: 'organization',
      }}
      fullWidth
    />
    <Field
      required
      name='email'
      type='email'
      label='Email'
      component={TextField}
      margin='normal'
      inputProps={{
        autoComplete: 'username',
      }}
      fullWidth
    />
    <Field
      required
      name='password'
      type='password'
      label='Contraseña'
      component={TextField}
      margin='normal'
      inputProps={{
        autoComplete: 'current-password',
      }}
      fullWidth
    />
    <Field
      name='otp'
      type='text'
      label='Código de autentificación'
      component={TextField}
      margin='normal'
      inputProps={{
        autoComplete: 'one-time-code',
      }}
      fullWidth
    />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <Button
      disabled={isSubmitting || !isValid}
      variant='contained'
      type='submit'
      size='large'
      fullWidth
      sx={{ mt: 2 }}
    >
      {'Ingresar'}
    </Button>
  </Form>
)

const LoginPage = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [userLogin] =
    useMutation<AdminLoginData, AdminLoginVars>(ADMIN_LOGIN_MUTATION, {
      errorPolicy: 'all',
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await userLogin({ variables: values })
    const credentials = response.data?.adminLogin?.credentials

    if (credentials) {
      setCredential(credentials)
      setManagedDomain(values.domain)
      window.location.href = '/app/'
    } else {
      setFormError(formRef, translateGuitaError(response))
    }
  }

  if (isLoggedIn()) {
    window.location.href = '/app/'
    return null
  }

  return (
    <React.Fragment>
      <SeoHeaders title='Iniciar Sesión' />
      <NavBar />
      <Container
        maxWidth='xs'
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
          gap: 2,
          px: 2,
          pt: 2,
          pb: 4,
        })}
      >
        <Image
          src={GuitaLogo}
          alt='Logo Guita'
          duration={300}
        />
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => <LoginForm {...props} />}
        </Formik>
        <Button
          fullWidth
          variant='outlined'
          color='secondary'
          size='large'
          href='/auth/password-reset/'
        >
          ¿Olvidaste tu contraseña?
        </Button>
      </Container>
    </React.Fragment>
  )
}

export default LoginPage
